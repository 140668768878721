import React, { useEffect, useState } from 'react';
import './App.css';
import IconButton from '@material-ui/core/IconButton';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import Replay10Icon from '@material-ui/icons/Replay10';
import Forward10Icon from '@material-ui/icons/Forward10';
import BrushIcon from '@material-ui/icons/Brush';
import SpeedIcon from '@material-ui/icons/Speed';
import PencilIcon from '@material-ui/icons/Edit';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createMuiTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import axios from 'axios';

import syllable from 'syllable';

var inputUrl = Object.fromEntries(window.location.search.slice(1).split('$').map(i=>i.split('=')).map(i=>[decodeURIComponent(i[0]),decodeURIComponent(i[1])])).url;

function s(a) {
  if(!a) return 2;
  var x = syllable(a);
  return x+1;
}

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }
    
    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

function App() {
  var size = useWindowSize();
  function settingsPopup() {
    var x = prompt(`Insert New Speed (Current: ${bwpm})`);
    if(!x) return;
    var newWPM = Number(x);
    setBWpm(newWPM);
    if(wpm!=0) setWpm(newWPM);
  }

  var [urlToImport,setUrlToImport] = React.useState(inputUrl||'');
  var [urlImport,setUrlImport] = React.useState(!!inputUrl);
  var [themeSelectedOpen,setThemeSelectedOpen] = React.useState(0);
  var [textImporting,setTextImporting] = React.useState(false);
  var [themeN,setTheme_] = React.useState(Number(localStorage.theme||"0"));
  function setTheme(a){
    setTheme_(a);
    localStorage.theme=(a%6).toString();
  }
  async function importFromURL(url) {
    if(url) {
      try {
        setUrlImport(false);
        setTextImporting(true);
        setUrlToImport('');
        var t = await axios.get('https://read.sohcah.dev/.netlify/functions/get?url='+encodeURIComponent(url))
        setFullText(t.data);
        setTextImporting(false);
      } catch (e) {
        setTextImporting(false);
      }
    } else {
      setUrlImport(true);
    }
    // var url = prompt('URL:');
  }
  var theme = [
    {
      bg: '#121212',
      fg: '#ffffff',
      name: 'Dark',
      dark: true
    },{
      bg: '#000000',
      fg: '#d3d3d3',
      name: 'Extra Dark',
      dark: true
    },{
      bg: '#ffffff',
      fg: '#000000',
      name: 'White'
    },{
      bg: '#aaaaaa',
      fg: '#222222',
      name: 'Grey'
    },{
      bg: '#107896',
      fg: '#ffffff',
      name: 'Blue',
      dark: true
    },{
      bg: '#ffffff',
      fg: '#107896',
      name: 'Blue Light'
    }
  ][themeN%6];

  const MUItheme = createMuiTheme({
    palette: {
      primary: {main:theme.fg},
      type: theme.dark?'dark':'light',
    },
  });
  var [count,setCount] = useState(0);
  var [bwpm,setBWpm_] = useState(Number(localStorage.wpm||"720"));
  function setBWpm(a){
    setBWpm_(a);
    localStorage.wpm=a.toString();
  }
  var [wpm,setWpm] = useState(0);
  useEffect(()=>{
    if(wpm) {
      var interval = setInterval(()=>{
        setCount(c=>{
          if(c%100+1>=s(full_text.split(/\s/)[Math.floor(c/100)])) {
            return (Math.floor(c/100)+1)*100
          } else {
            return c+1;
          }
        });
      },60000/wpm);
      return ()=>clearInterval(interval);
    } else {
      return ()=>{}
    }
  },[wpm])
  var [done,setDone] = useState(false)
  var [full_text,setFullText] = useState("");
  let c = Math.floor(count/100);
  var textpre = full_text.split(/\s/)[c-1] || "---"
  var text = full_text.split(/\s/)[c] || "---"
  var textnex = full_text.split(/\s/)[c+1] || "---"
  return (
    <ThemeProvider theme={MUItheme}>
      <div style={{display:"flex",height:'100vh',width:'100%',backgroundColor:theme.bg,color:theme.fg,alignItems:done&&size.height>400&&size.width>600?"center":null,justifyContent:"center"}}>
        <div style={{display:"flex",flex:1,height:done&&size.height>400&&size.width>600?400:null,alignItems:"center",flexDirection:"column"}}>
          {done&&<div style={{display:"flex",justifyContent:"center"}}>
            <IconButton color={theme.fg} aria-label="edit" onClick={()=>setDone(false)}>
              <PencilIcon fontSize="large" style={{color:theme.fg}} />
            </IconButton>
            <IconButton color={theme.fg} aria-label="speed" onClick={settingsPopup}>
              <SpeedIcon fontSize="large" style={{color:theme.fg}} />
            </IconButton>
            <IconButton color={theme.fg} aria-label="theme" onClick={()=>{setTheme(themeN+1);setThemeSelectedOpen(t=>t+1)}}>
              <BrushIcon fontSize="large" style={{color:theme.fg}} />
            </IconButton>
          </div>}
          <div style={{display:"flex",flex:1,width:"100%",justifyContent:"center",flexDirection:"column"}} onClick={done?()=>{
            if(wpm) {
              setWpm(0)
            } else {
              setWpm(bwpm)
            }
          }:null}>
            {done&&<div>
              <div style={{fontSize:16,textAlign:"center",opacity:0.3,lineHeight:0.5}}>{textpre}</div>
              <div style={{fontSize:32,textAlign:"center"}}>{text}</div>
            </div>}
            {!done&&<div style={{display:"flex",alignItems:"center",flexDirection:"column"}}>
            <button style={{fontSize:24,borderRadius:8,border: `2px solid ${theme.fg}`,backgroundColor:theme.bg,color:theme.fg}} onClick={()=>importFromURL()}>Import from URL</button>
              <textarea cols={75} style={{fontSize:'1.2em',marginTop:8,maxWidth:"calc(100vw - 16px)",borderRadius:8,minHeight:200,border: `2px solid ${theme.fg}`,backgroundColor:theme.bg,color:theme.fg}} value={full_text} onChange={(ev)=>setFullText(ev.target.value)}/>
              <button style={{fontSize:24,borderRadius:8,marginTop:8,border: `2px solid ${theme.fg}`,backgroundColor:theme.bg,color:theme.fg}} onClick={()=>{setDone(true);setCount(-1)}}>Read Text</button>
              <div style={{marginTop:8}}>Bookmarklet - Drag to your bookmark bar</div>
              <a className="unselectable" style={{textDecorationLine:"none"}} onClickCapture={(e)=>e.preventDefault()} href="javascript:location.href=`https://read.sohcah.dev/?url=${encodeURIComponent(location.href)}`"><div className="unselectable" style={{fontSize:24,borderRadius:8,paddingLeft:8,paddingRight:8,border: `2px solid ${theme.fg}`,backgroundColor:theme.bg,color:theme.fg}}>SpeedRead this Page</div></a>
            </div>}
          </div>
          {done&&<div style={{display:"flex",justifyContent:"center"}}>
            <IconButton color={theme.fg} aria-label="rewind" onClick={()=>setCount(0)}>
              <SkipPreviousIcon fontSize="large" style={{color:theme.fg}} />
            </IconButton>
            <IconButton color={theme.fg} aria-label="rewind" onClick={()=>setCount(Math.max(0,count-1000))}>
              <Replay10Icon fontSize="large" style={{color:theme.fg}} />
            </IconButton>
            {!!wpm&&<IconButton color={theme.fg} aria-label="pause" onClick={()=>setWpm(0)}>
              <PauseIcon fontSize="large" style={{color:theme.fg}} />
            </IconButton>}
            {!wpm&&<IconButton color={theme.fg} aria-label="resume" onClick={()=>setWpm(bwpm)}>
              <PlayArrowIcon fontSize="large" style={{color:theme.fg}} />
            </IconButton>}
            <IconButton color={theme.fg} aria-label="forward" onClick={()=>setCount(count+1000)}>
              <Forward10Icon fontSize="large" style={{color:theme.fg}} />
            </IconButton>
            <div style={{padding:10}}>
              <CircularProgress size={39} variant="static" value={Math.max(0,Math.min(100,(Math.floor(count/100)/(full_text.split(/\s/).length))*100))} />
            </div>
          </div>}
          <Snackbar open={themeSelectedOpen>0} autoHideDuration={500} onClose={()=>setThemeSelectedOpen(t=>t-1)} message={`Switched Theme to ${theme.name}`} />
          <Snackbar open={textImporting} onClose={()=>setTextImporting(false)} message={`Importing Text...`} />
          <Dialog open={urlImport} onClose={()=>{setUrlImport(false)}} aria-labelledby="form-dialog-title">
            <DialogTitle style={{backgroundColor:theme.bg,color:theme.fg}} id="form-dialog-title">Import from URL</DialogTitle>
            <DialogContent style={{backgroundColor:theme.bg,color:theme.fg}}>
              <TextField
                inputStyle={{color:theme.fg}}
                color="primary"
                autoFocus
                margin="dense"
                id="url"
                label="URL"
                type="url"
                fullWidth
                value={urlToImport}
                onChange={(ev)=>setUrlToImport(ev.target.value)}
              />
            </DialogContent>
            <DialogActions style={{backgroundColor:theme.bg}}>
              <Button onClick={()=>{setUrlImport(false)}} color="primary">
                Cancel
              </Button>
              <Button onClick={()=>{importFromURL(urlToImport)}} color="primary">
                Import
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
